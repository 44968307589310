<style scoped>
.form-group {
    margin-bottom: .1rem;
}

.shadow-textarea textarea.form-control::placeholder {
    font-weight: 300;
}
.shadow-textarea textarea.form-control {
    padding-left: 0.8rem;
}
</style>


<template>


<div>

        <div class="container">
<b-form class="form" @submit.stop.prevent="submit">


<div class="subheader py-2 py-lg-4 mb-5 gutter-b subheader-solid" id="kt_subheader">
                            <div class="container-fluid d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap">
                                <!--begin::Info-->
                                <div class="d-flex align-items-center flex-wrap mr-2">
                                    <!--begin::Page Title-->
                                    <h5 class="text-dark font-weight-bold mt-2 mb-2 mr-5">Edit a  Retailers Business Profile</h5>
                                    <!--end::Page Title-->
                                    <!--begin::Action-->
                                    <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>

                                    <span class="text-muted font-weight-bold mr-4"> </span>
 <div class="subheader-separator subheader-separator-ver mt-2 mb-2 mr-4 bg-gray-200"></div>



                                    <!--end::Action-->
                                </div>



								<div class="d-flex align-items-center">
									<button type="submit" class="btn btn-success mr-2">Save & Continue Edit</button>
								
								
	
								
								</div>
                                <!--end::Info-->

                            </div>






              </div>





<div class="row">

<div class="col-4">

                            <v-card dark class="h-100">
							   <v-card-text>
   

      <v-autocomplete
        v-model="company"
        :items="companies"
        :search-input.sync="search"
		@change="companySelected"
        color="white"
        hide-no-data
        hide-selected 
        item-text="company_name"
        item-value="id"
        label="Companies"
        placeholder="Choose Company to Edit"
        prepend-icon="mdi-database-search"
        return-object
      ></v-autocomplete>
    </v-card-text>

                            </v-card>
						</div>

						<div class="col-8">

<div class="card">
	<div class="card-header">


							<h3 class="card-title"> Profile Application Builder				</h3>
                            <p> This is the information that will be sent to potential Suppliers when you apply to become partners. </p>
	</div>
</div>
                        
                        </div>
						
					</div>



				
			
	

            <div class="row">
                <div class="col-lg-6">

                    	<!--begin::Card-->
		<div class="card card-custom gutter-b example example-compact">
		<div class="card-header">

			<h3 class="card-title"> Contact Info				</h3>
						</div>
			
				<div class="card-body">
				
				
                <div class="form-group row">
						<label  class="col-5 col-form-label">First Name</label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="your display name" id="profile_first" v-model="firstname"/>
						</div>
					</div>
                <div class="form-group row">
						<label  class="col-5 col-form-label">Last Name</label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="your display name" id="profile_last" v-model="lastname"/>
						</div>
					</div>






                    <div class="form-group row">
						<label  class="col-5 col-form-label">Title</label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="your display name" id="profile_title" v-model="title"/>
						</div>
					</div>

<div class="form-group row">
						<label for="example-email-input" class="col-5 col-form-label">Contact Email</label>
						<div class="col-7">
							<b-form-input
								class="form-control"
								id="example-input-1"
								name="example-input-1"
								v-model="$v.email.$model"
								:state="validateState('email')"
								aria-describedby="input-1-live-feedback"
							></b-form-input>

							<b-form-invalid-feedback id="input-1-live-feedback">
								Email is required and a valid email address.
							</b-form-invalid-feedback>
						</div>
				</div>

				<div class="form-group row">
						<label  class="col-5 col-form-label">Phone</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_phone" v-model="phone"/>
						</div>
				</div>

<div class="form-group row">
						<label  class="col-5 col-form-label">Website</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_website" v-model="website"/>
						</div>
				</div>

				</div>

		</div>
                    
                    
                    <div class="card card-custom gutter-b example example-compact">
		<div class="card-header">

			<h3 class="card-title"> Business Address				</h3>
						</div>
		
				<div class="card-body">


                    
                    
                    <div class="form-group row">
						<label  class="col-5 col-form-label">Address Line 1 </label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="line 1" id="profile_address1" v-model="addressline1"/>
						</div>
					</div>
                    <div class="form-group row">
						<label  class="col-5 col-form-label">Address Line 2 </label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="line 2" id="profile_address2" v-model="addressline2"/>
						</div>
					</div>



		            <div class="form-group row">
						<label  class="col-5 col-form-label">City</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_city" v-model="city"/>
						</div>
					</div>

				<div class="form-group row">
						<label  class="col-5 col-form-label">State</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_state" v-model="state"/>
						</div>
				</div>

	<div class="form-group row">
						<label  class="col-5 col-form-label">Zip</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_zip" v-model="zip"/>
						</div>
				</div>


				
				
				



				</div>
                    </div>

			
		
		<!--end::Card-->

   






                </div>

                <div class="col-lg-6">



<div class="card card-custom gutter-b example example-compact">
		<div class="card-header">

			<h3 class="card-title"> Business Info				</h3>
						</div>
		
				<div class="card-body">






                    <div class="form-group row">
						<label  class="col-5 col-form-label">Legal Company Name</label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="your display name" id="profile_name" v-model="companyname"/>
						</div>
					</div>

                <div class="form-group row">
						<label  class="col-5 col-form-label">DBA/ Store name (if different) </label>
						<div class="col-7">
							<input class="form-control" type="text" placeholder="your display name" id="profile_dba" v-model="storename"/>
						</div>
					</div>
					
					<div class="form-group row">
						<label  class="col-5 col-form-label">Company Type</label>
						<div class="col-7">
							<select class="custom-select form-control" v-model="companyType">
								<option value="brick">Brick and Mortar</option>
								<option value="onlineonly">Online Only</option>
								<option value="localonline">Local & Online</option>
							</select>
						</div>
					</div>





<div class="form-group row">
						<label  class="col-5 col-form-label"> Business Type</label>
						<div class="col-7">
							<select class="custom-select form-control" v-model="businessType">
								<option value="soleproprietor">Sole Proprieter</option>
								<option value="llc">LLC</option>
								<option value="corporation">Corporation</option>
							</select>

			</div>
				</div>







<div class="form-group row">
						<label  class="col-5 col-form-label">Tax ID #</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="tax_id" v-model="tax_Id"/>
						</div>
				</div>

                

			<div class="form-group row">
						<label  class="col-5 col-form-label">State of Incorporation</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="state_inc" v-model="stateOfIncorporation"/>
						</div>
				</div>


				<div class="form-group row">
						<label class="col-5 col-form-label" for="gridCheck">Have Reseller Form </label>
						<div class="col-7">
							<input class="form-check-input" type="checkbox" id="gridCheck" v-model="haveResellerForm">
						</div>
				</div>

				<div class="form-group row">
						<label  class="col-5 col-form-label">Number of Locations</label>
						<div class="col-7">
							<input class="form-control" type="text"  id="profile_map_policy" v-model="numberOfLocations"/>
						</div>
				</div>

				<div class="form-group row">

					<label  class="col-5 col-form-label">Reseller Tax Form</label>

					<div class="col-7">

						<b-form-file
							v-model="resellerTaxForm"
							:state="Boolean(resellerTaxForm)"
							:placeholder="resellerTaxFormName == '' ? 'Drop file here...' : resellerTaxFormName"
							drop-placeholder="Drop file here..."
							accept="image/*,application/pdf" 
						></b-form-file>

					</div>
				</div>

				<div class="form-group row">

					<label  class="col-5 col-form-label">W-9</label>

					<div class="col-7">

						<b-form-file
							v-model="w9"
							:state="Boolean(w9)"
							:placeholder="w9Name == '' ? 'Drop file here...' : w9Name"
							drop-placeholder="Drop file here..."
							accept="image/*,application/pdf" 
						></b-form-file>

					</div>
				</div>

				</div>




                

</div>



          	<!--begin::Card-->
		<div class="card card-custom gutter-b example example-compact">
		<div class="card-header">

			<h3 class="card-title"> Your Story				</h3>
						</div>
			
				<div class="card-body">
				<div class="form-group shadow-textarea">
  <label for="exampleFormControlTextarea6">Tell us about your company? </label>
  <ckeditor :editor="editor" v-model="companyDesc" :config="editorConfig"></ckeditor>
</div>



				<div class="form-group shadow-textarea mt-8">
  <label for="exampleFormControlTextarea6">Why do customers love your stores? </label>
  <ckeditor :editor="editor" v-model="customerDesc" :config="editorConfig"></ckeditor>
</div>


				</div>

		</div>


        	<!--begin::Card-->
		<div class="card card-custom gutter-b example example-compact">
		<div class="card-header">

			<h3 class="card-title"> References				</h3>
						</div>
			
				<div class="card-body">
				<div class="form-group shadow-textarea">
  <label for="exampleFormControlTextarea6">Trade Reference 1 </label>
  <ckeditor :editor="editor" v-model="tradeRef1" :config="editorConfig"></ckeditor>
</div>

<div class="form-group shadow-textarea mt-8">
  <label for="exampleFormControlTextarea6">Trade Reference 2 </label>
  <ckeditor :editor="editor" v-model="tradeRef2" :config="editorConfig"></ckeditor>
</div>
<div class="form-group shadow-textarea mt-8">
  <label for="exampleFormControlTextarea6">Trade Reference 3 </label>
  <ckeditor :editor="editor" v-model="tradeRef3" :config="editorConfig"></ckeditor>
</div>



				</div>

		</div>





           

		



		</div>
				
		</div>




</b-form>
        </div>










</div>


</template>

<script>
import Swal from "sweetalert2";
import { mapGetters, mapActions } from "vuex";
import gql from "graphql-tag";

import { validationMixin } from "vuelidate";
import { email, required } from "vuelidate/lib/validators";

import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
	mixins: [validationMixin],
	data() {
		return {
			company: null,
			search: "",
			companies: [],
			editor: ClassicEditor,
			editorConfig: {
				toolbar: {
					items: [
						'heading',
						'|',
						'bold',
						'italic',
						'bulletedList',
						'numberedList',
						'|',
						'insertTable',
						'|',
						'undo',
						'redo'
					]
				},
			},
			profileId: '',
			firstname: '',
			lastname: '',
			title: '',
			email: '',
			phone: '',
			website: '',
			addressline1: '',
			addressline2: '',
			city: '',
			state: 'AL',
			zip: '',
			companyname: '',
			storename: '',
			companyType: 'brick',
			businessType: 'soleproprietor',
			stateOfIncorporation: '',
			tax_Id: '',
			haveResellerForm: false,
			numberOfLocations: '',
			companyDesc: '',
			customerDesc: '',
			tradeRef1: '',
			tradeRef2: '',
			tradeRef3: '',
			resellerTaxForm: null,
			w9: null,
			resellerTaxFormName: '',
			w9Name: ''
		}
	},
	apollo: {
    companies: {
      query: gql`
        query Companies($search: String) {
          companies(
            where: {
				type: "seller",
              	company_name_contains: $search
            }) {
            id
            company_name
          }
        }
      `,
      variables() {
        return {
          search: this.search == null ? '' : this.search
        }
      },
    }
  },
	validations: {
		email: {
			required,
			email
		},
	},
	methods: {
		...mapActions([
			'getCompanyInfo',
			'updateBusinessProfileInfo'
		]),
		validateState(name) {
			const { $dirty, $error } = this.$v[name];
			return $dirty ? !$error : null;
		},
		companySelected() {
			this.getCompanyInfo({
				id: this.company.id
			})
			.then((res) => {
				this.profileId = res.data.business_profile.id;
				this.companyname = res.data.business_profile.company_name;
				this.companyType = res.data.business_profile.type;
				this.firstname = res.data.business_profile.first_name;
				this.lastname = res.data.business_profile.last_name;
				this.title = res.data.business_profile.title;
				this.email = res.data.business_profile.email;
				this.phone = res.data.business_profile.phone;
				this.website = res.data.business_profile.website;
				this.addressline1 = res.data.business_profile.addressline1;
				this.addressline2 = res.data.business_profile.addressline2;
				this.city = res.data.business_profile.city;
				this.state = res.data.business_profile.state;
				this.zip = res.data.business_profile.zip;
				this.storename = res.data.business_profile.store_name;
				this.businessType = res.data.business_profile.company_type;
				this.stateOfIncorporation = res.data.business_profile.state_of_incorporation;
				this.haveResellerForm = res.data.business_profile.have_reseller_form;
				this.numberOfLocations = res.data.business_profile.number_of_locations;
				if (res.data.business_profile.reseller_tax_form != null && res.data.business_profile.reseller_tax_form != undefined) {
					this.resellerTaxFormName = res.data.business_profile.reseller_tax_form.name;
				}
				else {
					this.resellerTaxFormName = '';
				}
				if (res.data.business_profile.w9 != null && res.data.business_profile.w9 != undefined) {
					this.w9Name = res.data.business_profile.w9.name;
				}
				else {
					this.w9Name = '';
				}
				this.companyDesc = res.data.business_profile.company_desc;
				this.customerDesc = res.data.business_profile.customer_desc;
				this.tradeRef1 = res.data.business_profile.trade_ref1;
				this.tradeRef2 = res.data.business_profile.trade_ref2;
				this.tradeRef3 = res.data.business_profile.trade_ref3;
				this.tax_Id = res.data.business_profile.tax_id;
			})
		},
		submit(e) {
			e.preventDefault();
			if (this.profileId != '') {
				this.updateBusinessProfileInfo({
					id: this.profileId,
					company_name: this.companyname,
					type: this.companyType,
					first_name: this.firstname,
					last_name: this.lastname,
					title: this.title,
					email: this.email,
					phone: this.phone,
					website: this.website,
					addressline1: this.addressline1,
					addressline2: this.addressline2,
					city: this.city,
					state: this.state,
					zip: this.zip,
					store_name: this.storename,
					company_type: this.businessType,
					state_of_incorporation: this.stateOfIncorporation,
					have_reseller_form: this.haveResellerForm,
					number_of_locations: this.numberOfLocations,
					reseller_tax_form: this.resellerTaxForm,
					w9: this.w9,
					company_desc: this.companyDesc,
					customer_desc: this.customerDesc,
					trade_ref1: this.tradeRef1,
					trade_ref2: this.tradeRef2,
					trade_ref3: this.tradeRef3,
					tax_id: this.tax_Id,
					search_content: this.companyname + '_' + this.companyDesc
				})
				.then((res) => {
					Swal.fire({
						title: "",
						text: "The profile has been successfully updated!",
						icon: "success",
						confirmButtonClass: "btn btn-secondary"
					});
				})
				.catch(() => {
					this.$bvToast.toast('An Error Occured!', {
						title: 'Error',
						variant: 'danger',
						toaster: 'b-toaster-bottom-right',
						solid: true,
						appendToast: true
					});
				});
			}
			else {
				this.$bvToast.toast('No profile in the company.', {
					title: 'Error',
					variant: 'danger',
					toaster: 'b-toaster-bottom-right',
					solid: true,
					appendToast: true
				});
			}
		}
	},  
 }
</script>